import React from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import Navbar from './navbar'
// import SideBar from './sidebar'

export default class Sidebar extends Base{

  state = {
    token : '',
    unread_notif : 0,

    menu_home_arr : [
        {title : 'Dashboard', nav : '/', icon : 'fas fa-home'},
        {title : 'Gym Info', nav : '/gym-info', icon : 'fas fa-info-circle'},
        {title : 'Kalender', nav : '/calendar', icon : 'fas fa-calendar'},
    ],

    menu_dropdown_arr : [
        {title : 'Data Gym', path : '/data-gym*', collapseType : 'collapseDataGym', detail_arr : [
            {title : 'Recap Order', nav : '/data-gym/recap-order', icon : ''},
            {title : 'Member Gym', nav : '/data-gym/member-gym', icon : ''},
            {title : 'Gym Class', nav : '/data-gym/list-gym-class', icon : ''},
            {title : 'Personal Trainer', nav : '/data-gym/list-personal-trainer', icon : ''},
        ]},
        {title : 'Master Produk', path : '/product*', collapseType : 'collapseMasterProduk', detail_arr : [
            {title : 'Create Membership', nav : '/product/membership', icon : ''},
            {title : 'Create Gym Class', nav : '/product/gym-class', icon : ''},
            {title : 'Create PT Program', nav : '/product/pt-program', icon : ''},
            {title : 'Add Product', nav : '/product/list', icon : ''},
        ]},
        {title : 'Finance Report', path : '/finance-report*', collapseType : 'collapseFinanceReport', detail_arr : [
            {title : 'General Finance Report', nav : '/finance-report/general-finance', icon : ''},
            {title : 'Membership Report', nav : '', icon : ''},
            {title : 'Gym Class Report', nav : '', icon : ''},
            {title : 'Gym PT Program Report', nav : '', icon : ''},
            {title : 'Gym Product Report', nav : '', icon : ''},
        ]},
        {title : 'Others', path : '', collapseType : 'collapseOthers', detail_arr : [
            {title : 'Referral Code Used', nav : '', icon : ''},
            {title : 'Communication Feature', nav : '', icon : ''},
        ]},
        {title : 'Master Operator', path : '', collapseType : 'collapseMasterOperator', detail_arr : [
            {title : 'List Operator', nav : '', icon : ''},
        ]},
    ],
  }
  
  async componentDidMount(){
    
  }

  render(){
    const {token, notif_arr, unread_notif, menu_home_arr, menu_dropdown_arr} = this.state
    const { route_params, is_sidebar_open } = this.props
    return (
      <>
      
      <ul className="navbar-nav bg-primary sidebar sidebar-dark accordion position-relative" id="accordionSidebar">

            <div className="text-center d-none d-md-inline position-absolute" style={{right : -12, top : 20, zIndex : 1000}}>
                <button className="rounded-circle border-0" id="sidebarToggle" style={{backgroundColor : '#3A57E8'}}></button>
            </div>
            <a className={"sidebar-brand d-flex align-items-center " + (is_sidebar_open ? 'justify-content-center' : 'pl-2')} href="/">
                <img src={this.img_logo} className={'sideBar_logo' + (is_sidebar_open ? ' show-side-bar' : '')} />
            </a>

            <div className="sidebar-heading text-white mb-2">Home</div>

            {
                menu_home_arr.map((data, index)=>(
                    <li className="nav-item" key={index}>
                        <a className="nav-link py-0" href={data.nav}>
                            <div className={'rounded py-3 ' + (route_params.match.url === data.nav ? ' active-sidebar' : '') + (!is_sidebar_open ? ' px-0' : ' px-0 px-md-3')}>
                                <i className={data.icon}></i>
                                <span>{data.title}</span>
                            </div>
                        </a>
                    </li>
                ))
            }
            
            <hr className="sidebar-divider" />
            
            {
                menu_dropdown_arr.map((data, index)=>(
                    <li className="nav-item" key={index}>
                        <a className={"nav-link" + (route_params.match.path !== data.path ? ' collapsed' : '')} href="#" data-toggle="collapse" data-target={'#'+data.collapseType}
                            aria-expanded="true" aria-controls={data.collapseType}>
                            <i className="fas fa-fw fa-cog"></i>
                            <span>{data.title}</span>
                        </a>
                        <div id={data.collapseType} className={"collapse" + (route_params.match.path === data.path && is_sidebar_open ? ' show' : '')} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className={"py-2 collapse-inner rounded bg-primary"}>
                                {
                                    data.detail_arr.map((detail, index_detail)=>(
                                        <div className={'rounded p-3' + (route_params.match.url === detail.nav ? ' active-sidebar' : '')}>
                                            <a className="collapse-item text-white p-0" href={detail.nav} key={index_detail}>{detail.title}</a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </li>
                ))
            }

        </ul>

      </>
    );
  }
}
