import React from 'react'
import Base from '../utils/base'
import Badge from './badge'

export default class CardProduct extends Base{
    state = {
    }

    async componentDidMount(){
    }
    
    render(){
        const { title, selectCard, viewType } = this.props
        return (
            <>
            <div className="card border-bottom-primary productCard" onClick={()=>selectCard()}>
                <div className="card-body p-3 py-2">
                    <div className="banner-169" style={{backgroundRepeat : 'no-repeat', backgroundImage : 'url('+ 'https://skystatic08.atmos.id/na.jpg' +')'}} ></div>
                    <div className='row mt-3'>
                        <div className='col d-flex align-items-center'>
                            <p className="m-0"><b>Nama</b></p>
                        </div>
                        <div className='col-auto text-right'>
                            <Badge title={'Aktif'} type={'success'} />
                        </div>
                        <div className='col-12 mt-2'>
                            <p style={{fontSize : 12, color : '#8A92A6'}}>Detail</p>
                        </div>
                        <div className='col-12 mt-4'>
                            <p className="m-0 text-primary font-weight-bold">Rp. 50.000 / Bulan</p>
                        </div>
                        {
                            viewType === 'productList' &&
                            <div className='col-12 mt-4 pt-2 pt-lg-0'>
                                <div className='row'>
                                    <div className='col-12 col-lg-6 mb-2 mb-lg-0 text-center cardFooter'>
                                        <p className='m-0 text-primary font-weight-bold cardFooterValue'>10</p>
                                        <p className='m-0 text-primary cardFooterCaption'>Sisa Stock</p>
                                    </div>
                                    <div className='col-12 col-lg-6 text-center cardFooter'>
                                        <p className='m-0 text-primary font-weight-bold cardFooterValue'>Rp. 50.000</p>
                                        <p className='m-0 text-primary cardFooterCaption'>Harga Beli</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}