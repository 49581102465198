import React from 'react'
import CardProduct from '../../components/cardProduct'
import MasterProductView from './masterProductView'
import Table from '../../components/table'
import TitleHeader from '../../components/titleHeader'
import Base from '../../utils/base'

export default class ProductList extends Base{
    state = {
        token : '',
        search : '',
        header_btn_arr : [
            {title : 'Tambah Product', icon : ''},
            {title : 'Tambah Stock', icon : 'fas fa-shopping-cart'},
        ],
        data_arr : [
            {title : '1'},
            {title : '2'},
            {title : '3'},
            {title : '4'},
        ]
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }
        // await localStorage.clear()
    }

    async actionBtn(){

    }

    async addProduct(index){
        console.log(index)

    }

    async selectCard(){
        
    }
    
    render(){
        const { search, headerTable_arr, actionBtnTable_arr, header_btn_arr, data_arr } = this.state
        return (
            <>
            <div className='row m-0'>
                <MasterProductView headerTitle={'List Product'} header_btn_arr={header_btn_arr} selectCard={()=>this.selectCard()} addButton={(index)=>this.addProduct(index)} type={'productList'} data_arr={data_arr} />
            </div>

            </>
        )
    }
}