import React from 'react'
import Table from '../../components/table'
import TitleHeader from '../../components/titleHeader'
import Base from '../../utils/base'

export default class RecapOrder extends Base{
    state = {
        token : '',
        search : '',
        headerTable_arr : [
            'ID Order',
            'Nama Member / Pembeli',
            'Jam Order',
            'Kategori Order',
            'Total',
            'Status',
            'Action',
        ],
        actionBtnTable_arr : [{title : 'View', type : 'primary', icon : ''}],
        data_arr : [
            [
                {type : 'text', value : 1},
                {type : 'text', value : 'Member ABC'},
                {type : 'text', value : '16:00'},
                {type : 'text', value : 'Member'},
                {type : 'text', value : 'Rp. 150.000'},
                {type : 'badge', value : 'Lunas'},
            ]
        ],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }
        // await localStorage.clear()
    }

    async actionBtn(){

    }
    
    render(){
        const { search, headerTable_arr, actionBtnTable_arr, data_arr } = this.state
        return (
            <>
            <div className='row m-0'>
                <TitleHeader title={'Recap Order'} />

                <div className='col-12 p-0 mt-3'>
                    <div className='row'>

                    <div className='col-12'>
                        <RecapOrderData search={search} headerTable_arr={headerTable_arr} actionBtnTable_arr={actionBtnTable_arr} data_arr={data_arr} />
                    </div>
                    <div className='col-12 mt-4'>
                        <RecapOrderData search={search} headerTable_arr={headerTable_arr} actionBtnTable_arr={actionBtnTable_arr} data_arr={data_arr} />
                    </div>

                    </div>
                </div>
                
            </div>

            </>
        )
    }
}

class RecapOrderData extends Base {
    render(){
        const { search, headerTable_arr, actionBtnTable_arr, data_arr } = this.props
        return(
            <>
            <div className='col-12'>
                <div className='row'>

                    <div className='col-6 col-lg mt-2 mt-lg-0 bg-white border-0 border-left-primary p-2 rounded shadow-sm'>
                        <p className='m-0'>Jumat, 20 Januari 2022</p>
                    </div>
                    <div className='col-6 col-lg mt-2 mt-lg-0 py-2'>
                        <div className='row'>
                            <div className='col-auto'>
                                <i class="fas fa-user-friends"></i>
                            </div>
                            <div className='col'>
                                <p className='m-0'>Operator ABC</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-lg mt-2 mt-lg-0 py-2'>
                        <div className='row'>
                            <div className='col-auto'>
                                <i class="fas fa-toggle-off"></i>
                            </div>
                            <div className='col'>
                                <p className='m-0'>Start : Pk. 07.00 WIB</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-lg mt-2 mt-lg-0 py-2'>
                        <div className='row'>
                            <div className='col-auto'>
                                <i class="fas fa-toggle-off"></i>
                            </div>
                            <div className='col'>
                                <p className='m-0'>End : Pk. 07.00 WIB</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='col-12 bg-white p-3 mt-4 shadow-sm rounded'>
                <div className='row'>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 col-lg" >
                                <div className='form-inline '>
                                    <div className="input-group">
                                        <input type="text" placeholder="Search" className="form-control bg-white small" autoComplete="off" value={search} onChange={(value)=>this.changeSearch(value.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-right col-lg-auto" style={{cursor : 'pointer'}}>
                                <i class="fas fa-sort-amount-up"></i>
                                <span className='m-0 ml-1'>Sort</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 mt-3'>
                        <Table headerTable_arr={headerTable_arr} actionBtnTable_arr={actionBtnTable_arr} data_arr={data_arr} actionBtn={()=>this.actionBtn()} is_table_button={true} />
                    </div>
                </div>
            </div>
            </>
        )
    }
}