import React from 'react'
import Base from '../utils/base'

export default class Badge extends Base{
    state = {
    }

    async componentDidMount(){
    }
    
    render(){
        const { title, type } = this.props
        const bgColor = (type === 'success' ? '#D5EBDF' : '')
        const textColor = (type === 'success' ? '#1AA053' : '')
        
        return (
            <>
            <span className='m-0 px-2 py-1 rounded' style={{backgroundColor : bgColor, fontSize : 12, border: ('1px solid ' + textColor), color : textColor}}>{title}</span>
            </>
        )
    }
}