import React, {Component} from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

export default class Base extends Component{
    
    url = 'https://api.rewang.co.id';
    url_image = 'https://api.rewang.co.id/images';
    timeoutAxios = 300000
    currencyFormat = 'id-ID'

    axios = axios
    moment = moment
    $ = Jquery

    constructor(props){
		super(props)
		axios.defaults.headers.common['Content-Type'] = 'application/json'
		axios.defaults.headers.common['Accept'] = 'application/json'
		axios.defaults.timeout = this.timeoutAxios
	}

    async request(url, method = "get", data = {}, onUploadProgress = response => {}){
		console.log(url)
		var token = await localStorage.getItem('token')
		if(token != null){
			axios.defaults.headers.common['Authorization'] = token
		}

		try{
		  var response
		  if(method === 'get'){
			  response = await axios.get(url)
		  }
		  else if(method === 'post'){
			  response = await axios.post(url, data, {
				  headers: {"Content-Type": "application/json"},
				onUploadProgress
			  })
		  }
		  else if(method === 'put'){
			  response = await axios.put(url, data, {
				headers: {"Content-Type": "application/json"},
				onUploadProgress
			  })
		  }
		  else if(method === 'delete'){
			  response = await axios.delete(url)
		  }

		  return response.data
		} catch (e) {
		  setTimeout(() => {
			console.log(url, e)
		  }, 500)
		}
	}

	img_no_image = require('../assets/img/no_image_available.jpeg')
	img_no_profile = require('../assets/img/no_profile_picture.png')
	img_welcome = require('../assets/img/welcome.png')
	img_logo = require('../assets/img/fitpro_logo.png')

    // logo = require('../assets/img/png/logo1.png').default
    // rewang_icon = require('../assets/img/png/logo_rewang_icon.png').default
    // no_user_img = require('../assets/img/png/no_profile_picture.png').default

    // mascot1 = require('../assets/img/png/REWANG-MASCOT-01.png').default
    // mascot2 = require('../assets/img/png/REWANG-MASCOT-02.png').default
    // mascot3 = require('../assets/img/png/REWANG-MASCOT-03.png').default
    // mascot4 = require('../assets/img/png/REWANG-MASCOT-04.png').default
    // mascot5 = require('../assets/img/png/REWANG-MASCOT-05.png').default

    // apple_badge = require('../assets/img/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg').default
    // android_badge = require('../assets/img/png/google-play-badge.png').default

    // rewang_mockup = require('../assets/img/png/rewang-mockup.png').default
}