import React from 'react'
import Base from '../utils/base'

export default class TitleHeader extends Base{
    state = {
    }

    async componentDidMount(){
    }
    
    render(){
        const { title } = this.props
        return (
            <>
            <div className='col-12 p-0'>
                <h3 className='font-weight-bold'>{title}</h3>
            </div>
            </>
        )
    }
}