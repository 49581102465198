import React from 'react'
import Table from '../../components/table'
import TitleHeader from '../../components/titleHeader'
import Base from '../../utils/base'

export default class GymClassList extends Base{
    state = {
        token : '',
        search : '',
        headerTable_arr : [
            'No',
            'Nama Kelas',
            'Instruktur',
            'Jumlah Member',
            'Jadwal Kelas',
            'Status',
        ],
        data_arr : [
            [
                {type : 'text', value : 1},
                {type : 'text', value : 'Kelas Yoga'},
                {type : 'text', value : 'Yoga'},
                {type : 'text', value : '15'},
                {type : 'text', value : 'Senin, Kamis 10:00'},
                {type : 'badge', value : 'Aktif', badge_type : 'success'},
            ]
        ],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }
        // await localStorage.clear()
    }

    async actionBtn(){

    }
    
    render(){
        const { search, headerTable_arr, actionBtnTable_arr, data_arr } = this.state
        return (
            <>
            <div className='row m-0'>
                <TitleHeader title={'List Gym Class'} />

                <div className='col-12 mt-5'>
                    <div className='row'>
                        <div className='col d-flex align-items-center'>
                            <div className='row'>
                                <div className='col-auto'>
                                    <i class="fas fa-user-friends"></i>  
                                </div>
                                <div className='col'>
                                    <p className='m-0'>Aktif : 5 Kelas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card shadow mb-4 w-100 mt-3">
                    <div class="card-body">
                        <div className='row'>
                            <div className="col-12">
                                <div className="input-group">
                                    <input type="text" placeholder="Search" className="form-control bg-white small" autoComplete="off" value={search} onChange={(value)=>this.changeSearch(value.target.value)} />
                                </div>
                            </div>

                            <div className='col-12 mt-3'>
                                <Table headerTable_arr={headerTable_arr} actionBtnTable_arr={actionBtnTable_arr} data_arr={data_arr} actionBtn={()=>this.actionBtn()} is_table_button={false} />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            </>
        )
    }
}