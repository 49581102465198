import React from 'react'
import Base from '../../utils/base'

export default class AuthLogin extends Base{
    state = {
        form_login_data : [
            {title : 'Email', value : '', type : 'email'},
            {title : 'Password', value : '', type : 'password'},
        ],
        error_data : {type : '', message : ''}
    }

    async componentDidMount(){
        await localStorage.clear()
    }

    async changeInput(val, index){
        await this.setError()
        var form_login_data = this.state.form_login_data
        form_login_data[index].value = val
        await this.setState({form_login_data : form_login_data})
    }

    async setError(type='', message=''){
        await this.setState({error_data : {type : type, message : message}})
    }

    async signIn(){
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        var form_login_data = this.state.form_login_data
        var flag = 1
        for(var x in form_login_data){
            if(form_login_data[x].value === ''){
                await this.setError(form_login_data[x].type, form_login_data[x].title + ' tidak boleh kosong')
                flag = 0
                break
            }
            if(form_login_data[x].type === 'email'){
                if(!form_login_data[x].value.match(mailformat)){
                    await this.setError('email', 'Invalid email format')
                    flag = 0
                    break
                }
            }
        }

        if(flag){
            await localStorage.setItem('token', '123123')
            window.location.href = '/'
        }
    }
    
    render(){
        const { form_login_data, error_data } = this.state
        return (
            <>
            
            <div className="row m-0">

                <div className='col-12'>
                
                    <div className='row'>
                        <div className='col-12 col-md-6 p-0 p-md-5' style={{height : '100vh'}}>

                            <div className='d-flex align-middle h-100 align-items-center p-0 p-md-5'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <h1>Sign In</h1>
                                        <h6 style={{color : '#8A92A6'}}>Sign in to stay connected.</h6>
                                    </div>

                                    <div className='col-12 px-3 px-md-5'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {
                                                    form_login_data.map((data, index)=>(
                                                        <div className="mb-3" key={index}>
                                                            <label className="form-label" style={{color : '#8A92A6'}}>{data.title}</label>
                                                            <input type={data.type} className={"form-control border border-primary" + (error_data.type === data.type ? ' border-danger' : '')} placeholder="" value={data.value} onChange={(val)=>this.changeInput(val.target.value, index)} />
                                                            {
                                                                error_data.type === data.type &&
                                                                <small id="emailHelp" className="form-text text-danger">{error_data.message}</small>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" for="flexCheckDefault" style={{color : '#8A92A6'}}>
                                                                Remember me?
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-auto text-end'>
                                                        <p className='textLink m-0 text-primary'>Forgot Password</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 text-center mt-3'>
                                        <button className='btn btn-primary px-5' onClick={()=>this.signIn()}>Sign In</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 d-none d-md-block" style={{height : '100vh', background : 'radial-gradient(circle, #3B8AFF, #4054B2)'}}>
                        </div>
                    </div>


                </div>

                

            </div>

            </>
        )
    }
}