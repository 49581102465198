import React from 'react'
import Base from '../../utils/base'
import Badge from '../../components/badge'
import CardProduct from '../../components/cardProduct'
import TitleHeader from '../../components/titleHeader'

export default class MasterProductView extends Base{
    state = {
    }

    async componentDidMount(){
    }
    
    render(){
        const { headerTitle, type, selectCard, addButton, header_btn_arr, data_arr } = this.props
        return (
            <>
            <TitleHeader title={headerTitle} />

            <div className='col-12 mt-3 p-0'>
                {
                    header_btn_arr.map((data, index)=>(
                        <button className='btn btn-sm btn-primary mr-2 mb-2 mb-md-0' key={index} onClick={()=>addButton(index)}><i className={data.icon + ' mr-2' + (data.icon !== '' ? ' d-inline-block' : ' d-none')}></i>{data.title}</button>
                    ))
                }
            </div>

            <div className='col-12 mt-3 p-0'>
                <div className='row m-0'>

                    {
                        data_arr.map((data, index)=>(
                            <div className={"col-6 col-md-3 mb-3 p-0 " + (index % 2 === 0 ? 'pr-1' : 'pl-1') + ' pl-md-0 pr-md-2'}>
                                <CardProduct selectCard={()=>selectCard()} viewType={type} />
                            </div>
                        ))
                    }

                </div>
            </div>
            </>
        )
    }
}