import React from 'react'
import Base from '../../../utils/base'

export default class TabInfo extends Base{
    state = {
    }

    async componentDidMount(){
    }
    
    render(){
        const { right_gym_info } = this.props
        return (
            <>
            <div className='row'>
                <div className='col-12 col-lg-8'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className="form-label font-weight-bold">Nama Gym</label>
                            <input type='text' className={"form-control border"} placeholder="" value={''} onChange={(val)=>this.changeInput(val.target.value)} />
                        </div>
                        <div className="col-12 mt-3">
                            <label className="form-label font-weight-bold">Alamat Gym</label>
                            <textarea className='form-control' rows={3} style={{resize : 'none'}}></textarea>
                        </div>
                        <div className='col-12 mt-3'>
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <label className="form-label font-weight-bold">No Telp Gym</label>
                                    <input type='text' className={"form-control border"} placeholder="" value={''} onChange={(val)=>this.changeInput(val.target.value)} />
                                </div>
                                <div className='col-6 pl-2'>
                                    <label className="form-label font-weight-bold">Whatsapp Gym</label>
                                    <input type='text' className={"form-control border"} placeholder="" value={''} onChange={(val)=>this.changeInput(val.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <label className="form-label font-weight-bold">Email Gym</label>
                            <input type='email' className={"form-control border"} placeholder="" value={''} onChange={(val)=>this.changeInput(val.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4 mt-3 mt-lg-0'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="banner-31" style={{backgroundSize : 'contain', backgroundRepeat : 'no-repeat', backgroundImage : 'url('+ 'https://skystatic08.atmos.id/na.jpg' +')'}} ></div>
                        </div>
                        <div className='col-12 mt-3'>
                            {
                                right_gym_info.map((data, index)=>(
                                    <p className='m-0' style={{color : '#8A92A6'}} key={index}>{data}</p>
                                ))
                            }
                        </div>

                        <div className='col-12 mt-3'>
                            <button className='btn btn-sm btn-primary'>Edit Info</button>
                        </div>
                    </div>
                </div>
            </div>

            </>
        )
    }
}