import React from 'react';
import Base from '../../utils/base';

import { BrowserRouter, Route } from 'react-router-dom';

import Navbar from './navbar';
import Sidebar from './sidebar';
import AuthLogin from '../auth/login';
import RecapOrder from '../dataGym/recapOrder';
import MemberGym from '../dataGym/memberGym';
import Membership from '../product/membership';
import GymClass from '../product/gymClass';
import PTProgram from '../product/PTProgram';
import ProductList from '../product/productList';
import HomeGymInfo from '../home/GymInfo';
import GymClassList from '../dataGym/gymClass';
import PersonalTrainerList from '../dataGym/personalTrainer';
import IndexHome from '../home/dashboard';
import Calendar from '../home/calendar';
import GeneralFinance from '../finance/generalFinance';

// import Navbar from './navbar'
// import SideBar from './sidebar'

export default class BaseLayout extends Base{

  state = {
    token : '',
    is_sidebar_open : true,
  }
  
  async componentDidMount(){
    var token = await localStorage.getItem('token')
    await this.setState({token : token})
    // await localStorage.clear()

    var $ = this.$
    var this1 = this

    this.$("#sidebarToggle, #sidebarToggleTop").on('click', async function(e) {
      await this1.setState({is_sidebar_open : !this1.state.is_sidebar_open})

        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        };
    });

    if (this.$(window).width() < 768) {
      this.$('.sidebar .collapse').collapse('hide');
    };
    
    // Toggle the side navigation when window is resized below 480px
    if (this.$(window).width() < 480 && !this.$(".sidebar").hasClass("toggled")) {
      this.$("body").addClass("sidebar-toggled");
      this.$(".sidebar").addClass("toggled");
      this.$('.sidebar .collapse').collapse('hide');
    };
  }

  render(){
    const {token, notif_arr, unread_notif, is_sidebar_open} = this.state
    return (
      <>
        <div id="wrapper">

        {/* sidebar */}

        {
          token !== '' && token != null &&
          <Sidebar route_params={this.props} is_sidebar_open={is_sidebar_open} />
        }
        
        <div id="content-wrapper" className={"d-flex flex-column" + (token !== '' && token != null ? ' pb-5' : '')}>
          <div id="content">

            {
              token !== '' && token != null &&
              <Navbar is_sidebar_open={is_sidebar_open} />
            }

            <div className={'pb-5 mb-5 ' + (token !== '' && token != null ? 'container-fluid px-3 px-md-4' : ' px-0 px-md-0')}>
              <BrowserRouter>
                <Route exact path={"/"} component={IndexHome} />
                <Route exact path={"/gym-info"} component={HomeGymInfo} />
                <Route exact path={"/calendar"} component={Calendar} />

                <Route exact path={"/auth/login"} component={AuthLogin} />

                <Route exact path={"/data-gym/recap-order"} component={RecapOrder} />
                <Route exact path={"/data-gym/member-gym"} component={MemberGym} />
                <Route exact path={"/data-gym/list-gym-class"} component={GymClassList} />
                <Route exact path={"/data-gym/list-personal-trainer"} component={PersonalTrainerList} />

                <Route exact path={"/product/membership"} component={Membership} />
                <Route exact path={"/product/gym-class"} component={GymClass} />
                <Route exact path={"/product/pt-program"} component={PTProgram} />
                <Route exact path={"/product/list"} component={ProductList} />

                <Route exact path={"/finance-report/general-finance"} component={GeneralFinance} />
              </BrowserRouter>
            </div>
          </div>
        </div>

        </div>
      </>
    );
  }
}
