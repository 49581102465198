import React from 'react'
import TitleHeader from '../../../components/titleHeader'
import Base from '../../../utils/base'
import AssetModal from './asset_modal'
import TabInfo from './info'

import { useParams } from "react-router-dom"


export default class HomeGymInfo extends Base{
    state = {
        token : '',
        tab_arr : [
            {title : 'Information', id : 'info', is_active : true, is_show : true},
            {title : 'Asset & Modal', id : 'asset_modal', is_active : false, is_show : true},
        ],
        right_gym_info : [
            'Gym ABC',
            'Jl. Ahmad Yani',
            'Jawa Timur, 12345',
            '03145678',
            '03145678',
            'email@abc.com',
        ],
        asset_modal_data_arr : [
            [
                {type : 'text', value : 1},
                {type : 'text', value : 'Gedung'},
                {type : 'text', value : 1},
                {type : 'badge', value : 'Fix Asset', badge_type : 'success'},
                {type : 'text', value : 'Rp. 200.000.000'},
                {type : 'text', value : 'Rp. 200.000.000'},
            ]
        ],
        input_data_table : [
            {type : '', value : ''},
            {type : 'text', value : ''},
            {type : 'number', value : ''},
            {type : 'badge', value : ''},
            {type : 'price', value : ''},
            {type : 'price', value : ''},
        ],
        actionBtnTable_arr : [{title : '', type : 'danger', icon : 'fas fa-trash-alt', btn_action : 'delete'}, {title : '', type : 'warning', icon : 'fas fa-pen', btn_action : 'edit'}],
    }


    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }

        // await localStorage.clear()
    }

    async actionBtnTable(index, indexBtn){
        var asset_modal_data = this.state.asset_modal_data_arr
        var actionBtnTable = this.state.actionBtnTable_arr[indexBtn]
        var data_id = asset_modal_data[index][0].value
        if(actionBtnTable.btn_action === 'delete'){
            console.log('delete')
        }
        else if(actionBtnTable.btn_action === 'edit'){
            console.log('edit')
        }
    }

    async changeInputModal(value, index){
        var input_data_table = this.state.input_data_table
        if(input_data_table[index].type === 'number'){
            var indexValue = value.length - 1
            if(value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57){
                input_data_table[index].value = value
            }
            else if(indexValue < 0){
                input_data_table[index].value = ''
            }
        }
        else{
            input_data_table[index].value = value
        }
        await this.setState({input_data_table : input_data_table})
    }

    async submit_inputTable(){
        var input_data_table = this.state.input_data_table
        var asset_modal_data_arr = this.state.asset_modal_data_arr

        var flag = 1

        for(var x in input_data_table){
            if(input_data_table[x].type === 'price'){
                input_data_table[x].value = 'Rp. ' + parseFloat(input_data_table[x].value).toLocaleString('id-ID')
            }

            if(input_data_table[x].type !== ''){
                if(input_data_table[x].value === ''){
                    flag = 0
                    break
                }
            }
        }

        if(flag){
            asset_modal_data_arr.push(input_data_table)
    
            input_data_table = [
                {type : '', value : ''},
                {type : 'text', value : ''},
                {type : 'number', value : ''},
                {type : 'badge', value : ''},
                {type : 'price', value : ''},
                {type : 'price', value : ''},
            ]
            await this.setState({asset_modal_data_arr_arr : asset_modal_data_arr, input_data_table : input_data_table})
        }
    }
    
    render(){
        const { tab_arr, right_gym_info, asset_modal_data_arr, actionBtnTable_arr, input_data_table } = this.state
        return (
            <>
            <div className='row m-0'>
                <TitleHeader title={'Gym Information'} />

                <div class="card shadow mb-4 w-100 mt-3">
                    <div class="card-body">
                        <div className='row'>
                            <div className="col-12">
                                <ul className="nav nav-tabs" id="tab_col" role="tablist">
                                {
                                    tab_arr.map((data, index)=>(
                                        <li className="nav-item">
                                            <a className={"nav-link" + (data.is_active ? ' active' : '')} id={data.id + "-tab"} data-toggle="tab" href={"#" + data.id} role="tab" aria-controls={data.id} aria-selected={'false'}>{data.title}</a>
                                        </li>
                                    ))
                                }
                                </ul>
                                <div className="tab-content mt-3" id="myTabContent">
                                {
                                    tab_arr.map((data, index)=>(
                                        <div className={"tab-pane fade" + (data.is_active ? ' show active' : '')} id={data.id} role="tabpanel" aria-labelledby={data.id + "-tab"} key={index}>
                                            {
                                                data.id === 'info' ?
                                                <TabInfo right_gym_info={right_gym_info} />
                                                :
                                                data.id === 'asset_modal' &&
                                                <AssetModal asset_modal_data_arr={asset_modal_data_arr} actionBtnTable_arr={actionBtnTable_arr} actionBtnTable={(index, indexBtn)=>this.actionBtnTable(index, indexBtn)} input_data_table={input_data_table} submit_inputTable={()=>this.submit_inputTable()} changeInputModal={(value, index)=>this.changeInputModal(value, index)} />
                                            }
                                        </div>
                                    ))
                                }
                                </div>
                                            
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            </>
        )
    }
}