import React from 'react';
// import logo from './logo.svg';
import Base from './utils/base'
// import './App.css';
import BaseLayout from './pages/layout/base';

import {BrowserRouter, Route} from 'react-router-dom'

export default class App extends Base {
  render(){
    return(
      <div className="App">
        {/* <Router /> */}

        <BrowserRouter>
          <Route exact path={"/"} component={BaseLayout} />
          <Route exact path={"/gym-info*"} component={BaseLayout} />

          <Route exact path={"/calendar*"} component={BaseLayout} />
          <Route exact path={"/auth*"} component={BaseLayout} />
          <Route exact path={"/data-gym*"} component={BaseLayout} />
          <Route exact path={"/product*"} component={BaseLayout} />
          <Route exact path={"/finance-report*"} component={BaseLayout} />
        </BrowserRouter>
      </div>
    )
  }
}