import React from 'react'
import Base from '../utils/base'
import Badge from './badge'

export default class Table extends Base{
    state = {
        token : '',
        search : ''
    }

    async componentDidMount(){
    }
    
    render(){
        const { is_sortable, headerTable_arr, data_arr, actionBtnTable_arr, actionBtn, is_table_button, tableBtnAction, is_input_table, input_data_table, submit_input, changeInputModal, idTable } = this.props
        return (
            <>
            <div className="table-responsive">
                <table className="table table-bordered" id={'dataTable'} width="100%" cellSpacing="0">
                    <thead>
                        <tr>
                            {
                                headerTable_arr.map((data, index)=>(
                                    <th className={'text-center font-weight-normal td-fit-content'} key={index}>{data}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data_arr.map((data, index)=>(
                                <tr key={index}>
                                    {
                                        data.map((data1, index1)=>(
                                            <td className={'td-fit-content'} key={index1}>
                                            {
                                                data1.type === 'text' || data1.type === 'number' || data1.type === 'price' ? 
                                                <p className='m-0'>{data1.value}</p>
                                                :
                                                data1.type === 'badge' ? 
                                                <span className="badge badge-success w-100">{data1.value}</span>
                                                : <></>
                                            }
                                            </td>
                                        ))
                                    }
                                    {
                                        is_table_button &&
                                        <td className='td-fit-content text-center'>
                                            {
                                                actionBtnTable_arr.map((dataBtn, indexBtn)=>(
                                                    <button className={'btn btn-sm btn-' + dataBtn.type + ' mr-2'} onClick={()=>tableBtnAction(index, indexBtn)}>
                                                        {
                                                            dataBtn.icon !== '' &&
                                                            <i className={dataBtn.icon}></i>
                                                        }
                                                        {
                                                            dataBtn.title !== '' &&
                                                            <p className='m-0'>{dataBtn.title}</p>
                                                        }
                                                    </button>
                                                ))
                                            }
                                        </td>
                                    }
                                </tr>
                            ))
                        }
                        {
                            is_input_table &&
                            <tr>
                                {
                                    input_data_table.map((data, index)=>(
                                        <td key={index}>
                                            {
                                                data.type !== '' &&
                                                <input type={'text'} className={"form-control form-control-sm border"} placeholder="" value={data.value} onChange={(val)=>changeInputModal(val.target.value, index)} />
                                            }
                                        </td>
                                    ))
                                }
                                <td className='td-fit-content text-center'>
                                    <button className={'btn btn-sm btn-primary'} onClick={()=>submit_input()}>
                                        <i className="fas fa-check"></i>
                                    </button>
                                </td>
                            </tr>

                        }
                    </tbody>
                </table>
            </div>

            </>
        )
    }
}