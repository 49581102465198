import React from 'react'
import Base from '../../../utils/base'
import {Bar} from 'react-chartjs-2'
import ModalFirstLogin from './modalFirstLogin'


export default class IndexHome extends Base{
    state = {
        token : '',
        card_arr : [
            {title : 'Member Baru', target : '30%'},
            {title : 'Gym Class Occupancy', target : '30%'},
            {title : 'PT Session Occupancy', target : '30%'},
            {title : 'Product Sales', target : '30%'},
        ],
        member_will_expired_arr : [
            {name : 'member name'},
            {name : 'member name'},
            {name : 'member name'},
        ],

        first_login_form : [
            {data_type : 'gym_name', value : '', type : 'text', title : 'Nama Gym', input_type : 'input'},
            {data_type : 'gym_address', value : '', type : 'text', title : 'Alamat Gym', input_type : 'textarea'},
            {data_type : 'gym_phone_data', data_row : [
                {data_type : 'gym_phone', value : '', type : 'text', title : 'No Telp Gym', input_type : 'input'},
                {data_type : 'gym_wa', value : '', type : 'text', title : 'Whatsapp Gym', input_type : 'input'},
            ]},
            {data_type : 'gym_email', value : '', type : 'email', title : 'Email Gym', input_type : 'input'},
        ],
        error_data : {type : '', message : ''},
        modal_type : 'data_gym',
        input_other_data_gym : [
            {type : 'text', value : ''},
            {type : 'number', value : ''},
            {type : 'badge', value : ''},
            {type : 'price', value : ''},
            {type : 'price', value : ''},
        ],
        other_data_gym_arr : [
            [{type : 'text', value : 'Gedung'},
            {type : 'text', value : 1},
            {type : 'badge', value : 'Fix Asset', badge_type : 'success'},
            {type : 'text', value : 'Rp. 200.000.000'},
            {type : 'text', value : 'Rp. 200.000.000'},]
        ],
        actionBtnTable_arr : [{title : '', type : 'danger', icon : 'fas fa-trash-alt', btn_action : 'delete'}, {title : '', type : 'warning', icon : 'fas fa-pen', btn_action : 'edit'}],

        is_first_login : 'true'
    }
    

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }

        console.log(this.props)

        // await localStorage.removeItem('is_first_login')

        var is_first_login = await localStorage.getItem('is_first_login')
        if(is_first_login == null){
            is_first_login = 'true'
        }
        await this.setState({is_first_login : is_first_login})
        
        if(is_first_login === 'true'){
            this.$('#modalUserData').modal('show')
        }
        // await localStorage.clear()
    }

    async setError(type='', message=''){
        await this.setState({error_data : {type : type, message : message}})
    }

    async changeInput(value, index, index_row=''){
        await this.setError()
        var first_login_form = this.state.first_login_form

        if(first_login_form[index].data_row == null){
            first_login_form[index].value = value
        }
        else{
            
            var indexValue = value.length - 1
            if(value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57){
                first_login_form[index].data_row[index_row].value = value
            }
            else if(indexValue < 0){
                first_login_form[index].data_row[index_row].value = ''
            }
        }

        await this.setState({first_login_form : first_login_form})
    }

    async modalActionBtn(type){
        if(type === 'data_gym'){
            var first_login_form = this.state.first_login_form
            var flag = 1
            
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            
            for(var x in first_login_form){
                if(first_login_form[x].data_row == null){
                    if(first_login_form[x].value === ''){
                        await this.setError(first_login_form[x].data_type, first_login_form[x].title + ' tidak boleh kosong')
                        flag = 0
                        break
                    }
                    if(first_login_form[x].data_type === 'gym_email'){
                        if(!first_login_form[x].value.match(mailformat)){
                            await this.setError('gym_email', 'Invalid email format')
                            flag = 0
                            break
                        }
                    }
                }
                else{
                    var data_row = first_login_form[x].data_row
                    var flag1 = 1
                    for(var y in data_row){
                        if(data_row[y].value === ''){
                            await this.setError(data_row[y].data_type, data_row[y].title + ' tidak boleh kosong')
                            flag1 = 0
                            break
                        }
                    }
                    flag = flag1
                    if(!flag1){
                        break
                    }
                }
            }

            if(flag){
                console.log('success')
                await this.setState({modal_type : 'other_data'})
            }
        }
        else if(type === 'other_data'){
            await localStorage.setItem('is_first_login', 'false')
            this.$('#modalUserData').modal('hide')
            window.location.reload()
        }
    }

    async submit_inputModal(){
        var input_other_data_gym = this.state.input_other_data_gym
        var other_data_gym = this.state.other_data_gym_arr

        for(var x in input_other_data_gym){
            if(input_other_data_gym[x].type === 'price'){
                input_other_data_gym[x].value = 'Rp. ' + parseFloat(input_other_data_gym[x].value).toLocaleString('id-ID')
            }
        }

        other_data_gym.push(input_other_data_gym)

        input_other_data_gym = [
            {type : 'text', value : ''},
            {type : 'number', value : ''},
            {type : 'badge', value : ''},
            {type : 'price', value : ''},
            {type : 'price', value : ''},
        ]
        await this.setState({other_data_gym_arr : other_data_gym, input_other_data_gym : input_other_data_gym})
    }

    async changeInputModal(value, index){
        var input_other_data_gym = this.state.input_other_data_gym
        if(input_other_data_gym[index].type === 'number'){
            var indexValue = value.length - 1
            if(value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57){
                input_other_data_gym[index].value = value
            }
            else if(indexValue < 0){
                input_other_data_gym[index].value = ''
            }
        }
        else{
            input_other_data_gym[index].value = value
        }
        await this.setState({input_other_data_gym : input_other_data_gym})
    }
    
    render(){
        const { card_arr, member_will_expired_arr, first_login_form, error_data, modal_type, input_other_data_gym, other_data_gym_arr, actionBtnTable_arr, data_chart1 } = this.state
        return (
            <>
            <div className='row'>
                <div className='col-12'>
                    <h5 className='font-weight-bold'>Morning, Gym Owner </h5>
                    <h6 style={{fontSize : 14}}>{this.moment().format('dddd, DD MMMM YYYY')}</h6>
                </div>

                <div className='col-12 mt-3'>
                    <div className='row m-0'>
                        {
                            card_arr.map((data, index)=>(
                                <div className={'col-12 col-lg p-0 mr-2 mb-2 mb-lg-0' + ((index != card_arr.length - 1) ? ' mr-lg-2' : '')} key={index}>
                                    <div className="card">
                                        <div className="card-body p-3">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-auto d-flex align-items-center'>
                                                            <p className="m-0" style={{color : '#8A92A6', fontSize : 14}}><b>{data.title}</b></p>
                                                        </div>
                                                        <div className='col text-right'>
                                                            <span className="badge badge-pill badge-success">This Month</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h3 className='m-0 font-weight-bold'>+5</h3>
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <div className='row'>
                                                        <div className='col-auto  d-flex align-items-center'>
                                                            <p className="m-0" style={{color : '#8A92A6', fontSize : 12}}>Target bulan ini</p>
                                                        </div>
                                                        <div className='col text-right'>
                                                            <p className="m-0" style={{fontSize : 14}}>{data.target}</p>
                                                        </div>
                                                        <div className='col-12 mt-2'>
                                                            <div className="progress" style={{height : 6}}>
                                                                <div className="progress-bar bg-success" role="progressbar" style={{width : data.target}} aria-valuenow={data.target} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='col-12 mt-3'>
                    <div className='row'>
                        <div className='col-12 col-lg-7'>
                            <div className="card shadow mb-4 w-100 mt-3">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h6 className='m-0 font-weight-bold'>Jumlah Pengunjung Gym</h6>
                                        </div>

                                        <div className='col-12 mt-3'>
                                            <div className="chart">
                                                <Bar
                                                    data={{
                                                        labels : ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                                                        datasets : [
                                                            {
                                                                label : '',
                                                                data : [12, 17, 15, 14, 10, 15, 15],
                                                                backgroundColor : '#3A57E8'
                                                            }
                                                        ]
                                                    }}
                                                    height={400}
                                                    width={600}
                                                    options={{
                                                        maintainAspectRatio : false,
                                                        scales : {
                                                            xAxes : [{
                                                                barThickness: 8,
                                                            }],
                                                            yAxes: [{
                                                                ticks: {
                                                                    min: 0,
                                                                }
                                                            }]
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-5'>

                            <div className="card shadow mb-4 w-100 mt-3">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h6 className='m-0 font-weight-bold' style={{color : '#8A92A6'}}>Membership yang akan Expired</h6>
                                        </div>

                                        <div className='col-12 mt-3'>
                                            <div className='row'>
                                                {
                                                    member_will_expired_arr.map((data, index)=>(
                                                        <div className={'col-12' + (index !== 0 ? ' mt-3' : '')} key={index}>
                                                            <div className='row'>
                                                                <div className='col-auto'>
                                                                    <div className="banner_1" style={{width : 48, height : 48, borderRadius : 48, backgroundSize : 'contain', backgroundRepeat : 'no-repeat', backgroundImage : 'url('+ 'https://skystatic08.atmos.id/na.jpg' +')'}} ></div>
                                                                </div>
                                                                <div className='col d-flex align-items-center'>
                                                                    <p className='m-0 text-capitalize'>{data.name}</p>
                                                                </div>
                                                                <div className='col d-flex flex-row-reverse align-items-center'>
                                                                    <p className='m-0' style={{color : '#8A92A6', fontSize : 14}}>12 Hari Lagi</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <ModalFirstLogin 
                first_login_form={first_login_form}
                changeInput={(value, index, index_row)=>this.changeInput(value, index, index_row)}
                modalActionBtn={(type)=>this.modalActionBtn(type)}
                error_data={error_data} 
                modal_type={modal_type}
                input_other_data_gym={input_other_data_gym}
                submit_input={()=>this.submit_inputModal()}
                other_data_gym_arr={other_data_gym_arr}
                actionBtnTable_arr={actionBtnTable_arr}
                changeInputModal={(value, index)=>this.changeInputModal(value, index)}
            />

            </>
        )
    }
}