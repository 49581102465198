import React from 'react'
import Base from '../../utils/base'
import {Bar} from 'react-chartjs-2'
import TitleHeader from '../../components/titleHeader'


export default class GeneralFinance extends Base{
    state = {
        token : '',
        data_total_arr : [
            {title : 'Jumlah Income', value : 'Rp. 150.000'},
            {title : 'Jumlah Membership', value : '10'},
            {title : 'Jumlah Outcome', value : 'Rp. 150.000'},
            {title : 'Jumlah Sales Product', value : 'Rp. 150.000'},
            {title : 'Jumlah Sales Kelas', value : '40'},
        ],
        card_arr : [
            {title : 'Customers', value : 70},
            {title : 'Customers', value : 70},
            {title : 'Customers', value : 70},
        ],
        month_data : this.moment().format('MMMM YYYY')
    }
    

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }

        // await localStorage.removeItem('is_first_login')

        var is_first_login = await localStorage.getItem('is_first_login')
        if(is_first_login == null){
            is_first_login = 'true'
        }
        await this.setState({is_first_login : is_first_login})
        
        if(is_first_login === 'true'){
            this.$('#modalUserData').modal('show')
        }
        // await localStorage.clear()
    }

    async monthBtn(type){
        var month_data = this.state.month_data

        if(type === 'prev'){
            month_data = this.moment(month_data).subtract(1, 'M').format('MMMM YYYY')
        }
        else{
            month_data = this.moment(month_data).add(1, 'M').format('MMMM YYYY')
        }

        await this.setState({month_data : month_data})
    }

    
    render(){
        const { card_arr, member_will_expired_arr, data_total_arr, month_data } = this.state
        return (
            <>
            <div className='row m-0'>
                <TitleHeader title={'General Finance Report'} />

                <div className='col-12 p-0 mt-3'>
                    <div className="card shadow mb-4 w-100 mt-3">
                        <div className="card-body">
                            <div className='row m-0'>
                                <div className='col-12 col-lg-7 p-0'>
                                    <div className='col-12'>
                                        <h6 className='m-0 font-weight-bold'>Today's Sales</h6>
                                        <p className='m-0' style={{color : '#8A92A6', fontSize : 14}}>04 Feb 2022</p>
                                    </div>

                                    <div className='col-12 mt-3 p-0 pr-lg-5'>
                                        <div className="chart">
                                            <Bar
                                                data={{
                                                    labels : ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                                                    datasets : [
                                                        {
                                                            label : '',
                                                            data : [12, 17, 15, 14, 10, 15, 15],
                                                            backgroundColor : '#3A57E8'
                                                        }
                                                    ]
                                                }}
                                                height={400}
                                                width={600}
                                                options={{
                                                    maintainAspectRatio : false,
                                                    scales : {
                                                        xAxes : [{
                                                            barThickness: 8,
                                                            gridLines : {
                                                                display : false
                                                            }
                                                        }],
                                                        yAxes: [{
                                                            ticks: {
                                                                min: 0,
                                                            },
                                                        }]
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-lg-5 p-0 mt-4 mt-lg-0'>
                                    <div className='row m-0'>
                                        {
                                            data_total_arr.map((data, index)=>(
                                                <div className={'col-12 text-center py-4 border'} key={index}>
                                                    <h6 className='m-0' style={{color : '#8A92A6'}}>{data.title}</h6>
                                                    <h4 className='m-0'>{data.value}</h4>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 mt-3 p-0'>
                    <div className='row m-0'>
                        {
                            card_arr.map((data, index)=>(
                                <div className={'col-12 col-lg mb-2 mb-lg-0 p-0' + (index % 2 !== 0 ? ' px-lg-2' : '')} key={index}>
                                    <div className="card">
                                        <div className="card-body p-3">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-auto d-flex align-items-center'>
                                                            <div className='bg-success p-2 px-3 rounded text-center'>
                                                                <i className="fas fa-user text-white" ></i>
                                                            </div>
                                                        </div>
                                                        <div className='col text-right'>
                                                            <p className="m-0" style={{color : '#8A92A6'}}>{data.title}</p>
                                                            <p className="m-0 font-weight-bold" style={{fontSize : 20}}>{data.value}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='col-12 mt-5 p-0'>
                    <div className='row m-0'>
                        <div className='col-12 mb-3 text-center'>
                            <div className='d-inline-block'>
                                <button className='btn btn-sm btn-primary' onClick={()=>this.monthBtn('prev')}><i className="fas fa-chevron-left"></i></button>
                            </div>
                            <div className='d-inline-block px-3'>
                                <p className='m-0'>{month_data}</p>
                            </div>
                            <div className='d-inline-block'>
                                <button className='btn btn-sm btn-primary' onClick={()=>this.monthBtn('next')}><i className="fas fa-chevron-right"></i></button>
                            </div>
                        </div>

                        <div className={'col-6 col-lg'}>
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-12 col-lg-auto'>
                                                    <div className='bg-success p-2 rounded text-center'>
                                                        <i className="fas fa-chart-pie text-white"></i>
                                                    </div>
                                                    <p className="m-0 font-weight-bold text-center text-lg-left mt-2 mt-lg-0" style={{fontSize : 14}}>$12000</p>
                                                </div>
                                                <div className='col-12 col-lg text-lg-right mt-2 mt-lg-0 d-flex h-100 align-items-center'>
                                                    <div className=''>
                                                        <p className="m-0" style={{color : '#8A92A6'}}>Total Sales</p>
                                                        <p className="m-0 font-weight-bold text-danger" style={{fontSize : 14}}>14%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className={'col d-none d-lg-inline-block'}>
                        </div>
                        <div className={'col-6 col-lg'}>
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-12 col-lg-auto'>
                                                    <div className='bg-success p-2 rounded text-center'>
                                                        <i className="fas fa-chart-pie text-white"></i>
                                                    </div>
                                                    <p className="m-0 font-weight-bold text-center text-lg-left mt-2 mt-lg-0" style={{fontSize : 14}}>$12000</p>
                                                </div>
                                                <div className='col-12 col-lg text-lg-right mt-2 mt-lg-0'>
                                                    <p className="m-0" style={{color : '#8A92A6'}}>Total Sales</p>
                                                    <p className="m-0 font-weight-bold text-danger" style={{fontSize : 14}}>14%</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </>
        )
    }
}