import React from 'react'
import Table from '../../../components/table'
import Base from '../../../utils/base'

export default class AssetModal extends Base{
    state = {
        headerTable_arr : ['ID', 'Nama Pengeluaran', 'Qty', 'Kategori', 'Harga', 'Harga Total', ''],
        
    }

    async componentDidMount(){
    }
    
    render(){
        const { right_gym_info, asset_modal_data_arr, is_table_button, tableBtnAction, actionBtnTable_arr, actionBtnTable, submit_inputTable, input_data_table, changeInputModal } = this.props
        const { headerTable_arr } = this.state
        return (
            <>
            <div className='row'>
                {/* <div className='col-12 text-right'>
                    <button className='btn btn-sm btn-primary'>Tambah Pengeluaran Tetap</button>
                </div> */}

                <div className='col-12 mt-3'>
                    <Table headerTable_arr={headerTable_arr} data_arr={asset_modal_data_arr} is_table_button={true} actionBtnTable_arr={actionBtnTable_arr} tableBtnAction={(index, indexBtn)=>actionBtnTable(index, indexBtn)}
                        submit_input={()=>submit_inputTable()} changeInputModal={(value, index)=>changeInputModal(value, index)} is_input_table={true} input_data_table={input_data_table} />
                </div>

                <div className='col-12 mt-3 text-right'>
                    <p className='m-0' style={{color : '#8A92A6'}}>Total Asset / Modal Gym</p>
                    <h4 className='m-0 font-weight-bold'>Rp. 100.000</h4>
                </div>

            </div>

            </>
        )
    }
}