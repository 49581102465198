import React from 'react'
import Base from '../../../utils/base'
import {Line} from 'react-chartjs-2'
import Table from '../../../components/table'

export default class ModalFirstLogin extends Base{
    state = {
        headerTable_arr : ['Nama Pengeluaran', 'Qty', 'Kategori', 'Harga', 'Harga Total', ''],
    }

    async componentDidMount(){
        
    }
    
    render(){
        const { first_login_form, changeInput, modalActionBtn, error_data, modal_type, input_other_data_gym, submit_input, other_data_gym_arr, actionBtnTable_arr, changeInputModal } = this.props
        const { headerTable_arr } = this.state
        return (
            <>

            <div className="modal fade" id="modalUserData" tabindex="-1" aria-labelledby="modalUserData" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className={"modal-dialog" + (modal_type === 'other_data' ? ' modal-lg' : '')}>
                    <div className="modal-content p-4">
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col'>
                                        <h6 className='m-0 font-weight-bold'>Welcome to Fitpro!</h6>
                                    </div>
                                    <div className='col text-right'>
                                        <p className='m-0' style={{color : '#8A92A6'}}>{modal_type === 'data_gym' ? 'Data Gym Anda' : 'Data lain Gym'}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 mt-2'>
                                {
                                    modal_type === 'data_gym' ?
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="banner_1" style={{width : 'auto', height : 120, backgroundSize : 'contain', backgroundRepeat : 'no-repeat', backgroundImage : "url(" + this.img_welcome +")"}} ></div>
                                        </div>

                                        <div className='col-12 mt-2'>
                                            <div className='row'>
                                                {
                                                    first_login_form.map((data, index)=>(
                                                        <>
                                                            <div className={'col-12' + (index !== 0 ? ' mt-3' : '')}>
                                                            {
                                                                data.data_row == null ?
                                                                <>
                                                                    <label className="form-label">{data.title}</label>
                                                                    {
                                                                        data.input_type === 'input' ?
                                                                        <input type={data.type} className={"form-control border"} placeholder="" value={data.value} onChange={(val)=>changeInput(val.target.value, index)} />
                                                                        : data.input_type === 'textarea' ?
                                                                        <textarea className="form-control" rows={3} style={{resize : 'none'}} placeholder="" onChange={(value)=>changeInput(value.target.value, index)} value={data.value}></textarea>
                                                                        : <></>
                                                                    }
                                                                    {
                                                                        error_data.type === data.data_type &&
                                                                        <small id="" className="form-text text-danger">{error_data.message}</small>
                                                                    }
                                                                </>
                                                                :
                                                                    <div className='row'>
                                                                        {
                                                                            data.data_row.map((data_row, index_row)=>(
                                                                                <div className={'col-6 ' + (index_row % 2 === 0 ? 'pr-2' : 'pl-2')}>
                                                                                    <label className="form-label">{data_row.title}</label>
                                                                                    <input type='text' className={"form-control border"} placeholder="" value={data_row.value} onChange={(val)=>changeInput(val.target.value, index, index_row)} />
                                                                                    {
                                                                                        error_data.type === data_row.data_type &&
                                                                                        <small id="" className="form-text text-danger">{error_data.message}</small>
                                                                                    }
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                            }
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : modal_type === 'other_data' &&
                                    <div className='row m-0'>

                                        <div className='col-12 mt-2'>
                                            <div className='row'>
                                                <Table headerTable_arr={headerTable_arr} is_table_button={true} is_input_table={true} input_data_table={input_other_data_gym} submit_input={()=>submit_input()} data_arr={other_data_gym_arr} actionBtnTable_arr={actionBtnTable_arr} changeInputModal={(value, index)=>changeInputModal(value, index)} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='col-12 mt-4'>
                                <button className={'btn btn-lg w-100 btn-' + (modal_type === 'data_gym' ? 'primary' : 'success')} onClick={()=>modalActionBtn(modal_type)}>{modal_type === 'data_gym' ? 'Next' : 'Apply'}</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            </>
        )
    }
}