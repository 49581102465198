import React from 'react'
import TitleHeader from '../../../components/titleHeader'
import Base from '../../../utils/base'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'


export default class Calendar extends Base{
    state = {
        token : '',
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        if(this.state.token === '' || this.state.token == null){
            window.location.href = '/auth/login'
        }
        // await localStorage.clear()
        
    }

    render(){
        return (
            <>
            <div className='row m-0'>
                <TitleHeader title={'Kalender'} />

                <div className='col-12 p-0 mt-3'>
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        height={'auto'}
                    />
                </div>

            </div>

            </>
        )
    }
}